// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-checkout-confirmation-js": () => import("./../../../src/templates/checkout/confirmation.js" /* webpackChunkName: "component---src-templates-checkout-confirmation-js" */),
  "component---src-templates-checkout-index-js": () => import("./../../../src/templates/checkout/index.js" /* webpackChunkName: "component---src-templates-checkout-index-js" */),
  "component---src-templates-checkout-place-order-js": () => import("./../../../src/templates/checkout/placeOrder.js" /* webpackChunkName: "component---src-templates-checkout-place-order-js" */),
  "component---src-templates-email-confirmation-js": () => import("./../../../src/templates/emailConfirmation.js" /* webpackChunkName: "component---src-templates-email-confirmation-js" */),
  "component---src-templates-forgot-password-js": () => import("./../../../src/templates/forgotPassword.js" /* webpackChunkName: "component---src-templates-forgot-password-js" */),
  "component---src-templates-login-js": () => import("./../../../src/templates/login.js" /* webpackChunkName: "component---src-templates-login-js" */),
  "component---src-templates-main-index-tsx": () => import("./../../../src/templates/main/index.tsx" /* webpackChunkName: "component---src-templates-main-index-tsx" */),
  "component---src-templates-myaccount-js": () => import("./../../../src/templates/myaccount.js" /* webpackChunkName: "component---src-templates-myaccount-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-password-reset-js": () => import("./../../../src/templates/passwordReset.js" /* webpackChunkName: "component---src-templates-password-reset-js" */),
  "component---src-templates-register-js": () => import("./../../../src/templates/register.js" /* webpackChunkName: "component---src-templates-register-js" */),
  "component---src-templates-share-locations-js": () => import("./../../../src/templates/shareLocations.js" /* webpackChunkName: "component---src-templates-share-locations-js" */),
  "component---src-templates-track-order-index-js": () => import("./../../../src/templates/trackOrder/index.js" /* webpackChunkName: "component---src-templates-track-order-index-js" */)
}

